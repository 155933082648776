
.custom_column{
    background: transparent;
    border-color: transparent;
}

.p-treetable-custom  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    background: red;
    color: blue;
}

.p-treetable-toggler-custom  {
    cursor: pointer;
    display: inline-block;
}

.p-treetable-custom  .p-treetable-thead > tr > th,
.p-treetable-custom  .p-treetable-tbody > tr > td,
.p-treetable-custom  .p-treetable-tfoot > tr > td {
    padding: .25em .5em;
}

.p-treetable-custom  .p-treetable-thead > tr > th .p-column-title {
    vertical-align: middle;
    background: red;
    color: blue;
}

.p-treetable-custom  .p-sortable-column {
    cursor: pointer;
}

.p-treetable .p-sortable-column-icon {
    vertical-align: middle;
}

.p-treetable-auto-layout > .p-treetable-wrapper {
    overflow-x: auto;
}

.p-treetable-auto-layout > .p-treetable-wrapper > table {
    table-layout: auto;
}

/* Sections */
.p-treetable-header,
.p-treetable-footer {
    padding: .25em .5em;
    text-align: center;
    font-weight: bold;
}

.p-treetable-header {
    border-bottom: 0 none;
}

.p-treetable-footer {
    border-top: 0 none;
}

/* Paginator */
.p-treetable .p-paginator-top {
    border-bottom: 0 none;
}

.p-treetable .p-paginator-bottom {
    border-top: 0 none;
}

/* Scrollable */
.p-treetable-scrollable-wrapper {
    position: relative;
}
.p-treetable-scrollable-header,
.p-treetable-scrollable-footer {
    overflow: hidden;
    border: 0 none;
}

.p-treetable-scrollable-body {
    overflow: auto;
    position: relative;
}

.p-treetable-scrollable-body > table > .p-treetable-tbody > tr:first-child > td {
    border-top: 0 none;
}

.p-treetable-virtual-table {
    position: absolute;
}

/* Frozen Columns */
.p-treetable-frozen-view .p-treetable-scrollable-body {
    overflow: hidden;
}

.p-treetable-frozen-view > .p-treetable-scrollable-body > table > .p-treetable-tbody > tr > td:last-child {
    border-right: 0 none;
}

.p-treetable-unfrozen-view {
    position: absolute;
    top: 0px;
}

/* Filter */
.p-column-filter {
    width: 100%;
}

/* Resizable */
.p-treetable-resizable > .p-treetable-tablewrapper {
    overflow-x: auto;
}

.p-treetable-resizable .p-treetable-thead > tr > th,
.p-treetable-resizable .p-treetable-tfoot > tr > td,
.p-treetable-resizable .p-treetable-tbody > tr > td {
    overflow: hidden;
}

.p-treetable-resizable .p-resizable-column {
    background-clip: padding-box;
    position: relative;
}

.p-treetable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
    display: none;
}

.p-treetable .p-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: .5em;
    height: 100%;
    padding: 0px;
    cursor:col-resize;
    border: 1px solid transparent;
}

.p-treetable .p-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
}

/* Selection */
.p-treetable .p-treetable-checkbox {
    margin: 0 .5em 0 .25em;
    vertical-align: middle;
}

/* Edit */
.p-treetable .p-treetable-tbody > tr > td.p-cell-editing .p-component {
    width: 100%;
}

/* Reorder */
.p-treetable-reorder-indicator-up,
.p-treetable-reorder-indicator-down {
    position: absolute;
    display: none;
}

/* Responsive */
.p-treetable-responsive .p-treetable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    .p-treetable-responsive .p-treetable-thead > tr > th,
    .p-treetable-responsive .p-treetable-tfoot > tr > td {
        display: none !important;
    }

    .p-treetable-responsive .p-treetable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none;
        width: 100% !important;
		float: left;
		clear: left;
    }

    .p-treetable-responsive .p-treetable-tbody > tr > td .p-column-title {
        padding: .4em;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4em;
        font-weight: bold;
    }
}

/* Loader */
.p-treetable-loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    z-index: 1;
}

.p-treetable-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
}

.p-treetable .p-treetable-loading-icon {
    font-size: 2em;
}

.p-treetable  .ui-icon {
    width: 10px;
    height: 10px;
}